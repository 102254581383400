import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DateTime from "../components/datetime"

const ProjectPost = ({data}) => {

  const post = data.markdownRemark
  return (
    <Layout>
      <Seo title={post.frontmatter.title} description={post.excerpt} />
      <section className="prose">
        <h1 style={{ marginBottom: `0.5rem` }}>{post.frontmatter.title}</h1>
        <DateTime
          datetime={post.frontmatter.htmldate}
          dateformatted={post.frontmatter.date}
        />
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        htmldate: date
        date(formatString: "MMMM YYYY")
        featuredImg {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
export default ProjectPost
